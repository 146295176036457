export const projectsLeft = [
    {
        id: "verksja",
        image: "/images/verksja.png",
        company: "Verksjá - Borgarlínan ",
        description: "Services included a website for desktop and mobile",
        link: "https://www.verksja.is",
        projectTitle: "",
        projectServices: "",
        tags: "",
    },
    {
        id: "abler",
        image: "/images/abler.png",
        company: "Abler",
        description: "Services included website, app, social media and keynote design",
        link: "https://www.abler.io/home/en/",
        projectTitle: "",
        projectServices: "",
        tags: "",
    },
    {
        id: "onefootball",
        image: "/images/onefootball.png",
        company: "Onefootball",
        description: "Services included app design",
        link: "https://onefootball.com",
        projectTitle: "",
        projectServices: "",
        tags: "",
    },
    {
        id: "anna-eiriks",
        image: "/images/annaeiriks.png",
        company: "Anna Eiríks",
        description: "Services included corporate logo, website and customer portal design",
        link: "https://annaeiriks.is",
        projectTitle: "",
        projectServices: "",
        tags: "",
    },
    {
        id: "samuel-and-gunnar",
        image: "/images/sandg-1.png",
        company: "Samuel & Gunnar",
        description: "Design for website",
        link: "https://samuelandgunnar.com",
        projectTitle: "",
        projectServices: "",
        tags: "",
    },
    {
        id: "audur",
        image: "/images/audur.png",
        company: "Auður",
        description: "Design for website",
        link: "https://audur.is",
        projectTitle: "",
        projectServices: "",
        tags: "",
    }
]

export const projectsRight = [
    {
        id: "outcome",
        image: "/images/outcome-new.png",
        company: "Outcome",
        description: "Services included website, web app and keynote design",
        link: "https://outcome.io",
        projectTitle: "",
        projectServices: "",
        tags: "",
    },
    {
        id: "fjarthjalfun",
        image: "/images/fjarthjalfun.png",
        company: "Fjarþjálfun",
        description: "Services included corporate logo, website and social media design",
        link: "https://www.fjarthjalfun.is/",
        projectTitle: "",
        projectServices: "",
        tags: "",
    },
    {
        id: "skot",
        image: "/images/skot.png",
        company: "Skot",
        description: "Services included website design",
        link: "https://skot.is",
        projectTitle: "",
        projectServices: "",
        tags: "",
    },
    {
        id: "sotheybys",
        image: "/images/sothebys.png",
        company: "SOTHEBY’S",
        description: "Design for app, website, social media",
        link: "https://sothebys.com",
        projectTitle: "Designing the next gen app for Sotheby’s",
        projectServices: "Services included website, app, social media and keynote design",
        tags: "UX, UI, Design system",
        images: [
            "/images/skot.png",
            "/images/blokast.png",
            "/images/onefootball.png",
            "/images/fjarthjalfun.png",
            "/images/outcome-new.png",
            "/images/annaeiriks.png",
        ],
    },
    {
        id: "blokast",
        image: "/images/blokast.png",
        company: "Sýn",
        description: "Design for app, website, social media",
        link: "https://tal.visir.is/",
        projectTitle: "",
        projectServices: "",
        tags: "",
    }
]
