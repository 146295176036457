import React from "react";

const CoverBlock = () => {
    return (
        <div className="intro">
            <div className="row">
                <div className="title">
                    <div className="d-block d-lg-flex justify-content-lg-between w-100">
                        <div>
                            <h1>Subscription based</h1>
                            <h1>Design agency</h1>
                        </div>
                        <div>
                            <a href="https://calendly.com/jonni-smorre/30min" target="_blank" className="d-none d-lg-block"><h1>Let's chat</h1></a>
                            <a href="#pricing" className="d-none d-lg-block"><h1>SEE PRICING</h1></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoverBlock;