import React from "react";

const Checkmark = () => {
    return (
        <svg className="checkmark" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6663 5L7.49967 14.1667L3.33301 10" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default Checkmark;
