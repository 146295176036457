import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.scss';
import Intro from '../src/components/intro.js';
import ProjectDetail from '../src/components/projectDetail.js';
import ProjectItem from '../src/components/projectItem'; // Import ProjectItem directly
import { projectsLeft, projectsRight } from '../src/data.js'; // Import project data
import Pricing from '../src/components/pricing.js';

function App() {
  const projects = [...projectsLeft, ...projectsRight]; // Combine both project lists

  return (
    <Router>
      <header className="App-header d-flex align-items-lg-center justify-content-between">
        <div className="logo d-flex justify-self-start">smørre</div>
        <div className="status-pill d-flex align-items-center">
          <span className="d-block green"></span>Available <span className="d-none d-sm-block ps-1">for projects</span>
        </div>
        <nav>
          <ul className="links-container d-flex p-0 m-0 flex-column flex-lg-row">
            <li className="links-item"><a href="https://dribbble.com/kastaniubrunn" className="link active">Dribbble</a></li>
            <li className="links-item"><a href="https://www.linkedin.com/in/kastaniubrunn/" className="link">LinkedIn</a></li>
            <li className="links-item"><a href="https://x.com/kastaniubrunn" className="link">X</a></li>
            <li className="links-item"><a href="https://www.instagram.com/kastaniubrunn/" className="link">Instagram</a></li>
          </ul>
        </nav>
      </header>
      <section className="App p-0">
        <Routes>  
        <Route 
            path="/" 
            element={
              <>
                <Intro />
                <div className="projects-container">
                  {/* Render all projects on the homepage */}
                  {projects.map((project) => (
                    <ProjectItem
                      key={project.id}
                      project={project} 
                    />
                  ))}
                </div>
                <Pricing />
              </>
            } 
          />
          <Route 
            path="/project/:projectId" 
            element={<ProjectDetail projects={projects} />} 
          />
        </Routes>
      </section>
      <footer>
        <a href="mailto:jonni@smorre.is" className="d-block">
          <h1>Let's chat</h1>
          <h1>HI@SMORRE.IS</h1>
        </a>
        <span>2024</span>
      </footer>
    </Router>
  );
}

export default App;
