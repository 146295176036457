import React from 'react';
import { useParams } from 'react-router-dom';

const ProjectDetail = ({ projects }) => {
  const { projectId } = useParams();
  const project = projects.find((p) => p.id === projectId);

  // Handle case where project is not found
  if (!project) return <div>Project not found</div>;

  // Split the tags string into an array
  const tagsArray = project.tags.split(',').map(tag => tag.trim());

  return (
    <div className="project-detail">
      <div className="row title-row">
        <div className="col-6">
          <div className="d-flex align-items-center title-container">
            <svg className="me-4" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.9609 13.0859L11.5859 19.4609L11.0703 20L11.5859 20.5391L17.9609 26.9141L19.0391 25.8359L13.9531 20.75H29V19.25H13.9531L19.0391 14.1641L17.9609 13.0859Z" fill="black" />
            </svg>
            <h1>{project.company}</h1>
          </div>
          <div className="tags d-flex">
            {tagsArray
              .filter(tag => tag) // Filter out empty tags
              .map((tag, index) => (
                <span key={index} className="tag">{tag}</span>
              ))}
          </div>
        </div>
        <div className="col-6 title">
          <div className="">
            <h1>{project.projectTitle}</h1>
            <p>{project.projectServices}</p>
          </div>
        </div>
      </div>
      {/* Check if images exist before mapping */}
      {project.images && project.images.length > 0 && (
        <div className="gallery d-flex flex-wrap">
          {project.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`${project.company}`} // More descriptive alt text
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectDetail;
