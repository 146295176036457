import React from "react";
import Checkmark from "./checkmark";

const Pricing = () => {
    return (
        <div className="pricing-container d-flex" id="pricing">
            <div className="light d-grid pr-20">
                <div className="price-title">LIGHT</div>
                <p>Ideal for startups and small businesses with short term or small projects.</p>
                <ul className="ps-0">
                    <li><Checkmark />Dedicated senior designer</li>
                    <li><Checkmark />3 hours of focused daily work</li>
                    <li><Checkmark />Weekly updates</li>
                    <li><Checkmark />No equipment needed</li>
                    <li><Checkmark />Pause or cancel anytime</li>
                </ul>
                <h2 className="align-self-end"><a href="https://calendly.com/jonni-smorre/30min" target="_blank">BOOK A CALL</a></h2>
            </div>
            <div className="advanced d-grid pr-20 pl-20">
                <div className="price-title">ADVANCED</div>
                <p>Ideal for large businesses or companies with a clear vision and long term goals.</p>
                <ul className="ps-0">
                    <li><Checkmark />Dedicated senior designer</li>
                    <li><Checkmark />5 hours of focused daily work</li>
                    <li><Checkmark />Daily or Weekly updates</li>
                    <li><Checkmark />No equipment needed</li>
                    <li><Checkmark />Pause or cancel anytime</li>
                </ul>
                <h2 className="align-self-end"><a href="https://calendly.com/jonni-smorre/30min" target="_blank">BOOK A CALL</a></h2>
            </div>
            <div className="custom d-grid pl-20">
                <div className="price-title">CUSTOM</div>
                <p>Ideal for small to medium projects like a single website or application.</p>
                <ul className="ps-0">
                    <li><Checkmark />Dedicated senior designer</li>
                    <li><Checkmark />Custom hours</li>
                    <li><Checkmark />Regular updates</li>
                    <li><Checkmark />No equipment needed</li>
                </ul>
                <h2 className="align-self-end"><a href="https://calendly.com/jonni-smorre/30min" target="_blank">BOOK A CALL</a></h2>
            </div>
        </div>
    );
};

export default Pricing;
