import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const ProjectItem = ({ project = {}, delay = 0 }) => {
    const itemRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');

                        setTimeout(() => {
                            entry.target.classList.add('visible');
                        }, delay); // Use the passed delay prop
                    }
                });
            },
            { threshold: 0.5 } // Trigger when 40% of the element is visible
        );

        if (itemRef.current) {
            observer.observe(itemRef.current);
        }

        return () => {
            if (itemRef.current) {
                observer.unobserve(itemRef.current);
            }
        };
    }, []);

    if (!project || Object.keys(project).length === 0) {
        return <div>No project available</div>;
    }

    const projectUrl = project.link || "#";

    return (
        // <Link to={`/project/${project.id}`} className="project-item" ref={itemRef}>
        <a
            href={projectUrl}
            className="project-item"
            ref={itemRef}
            target="_blank"
            rel="noopener noreferrer"
        >
            <div className="img-container">
                <img src={project.image} alt={project.company} loading="lazy" className="w-100" />
            </div>
            <div className="text">
                <h2>{project.company}</h2>
                <span>{project.description}</span>
            </div>
        </a>
        // </Link>
    );
};

export default ProjectItem;
